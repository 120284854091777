import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Services} from "./model/services";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OndemandExperienceService {

  //apiUrl = 'http://68.66.251.236:8444/on-demand-experience';
  apiUrl = 'https://madeinworldagency.com:8444/on-demand-experience';

  constructor(private http: HttpClient) { }

  getServices() :Observable<Services[]>{

    console.log("find services")
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };
    return this.http.get<Services[]>(this.apiUrl+'/find-all',{headers});
  }

  getService(id: number): Observable<Services> {

    console.log("find service by id =" +id)
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };

    return this.http.get<Services>(this.apiUrl+'/find/'+id,{headers});
  }
}
