<div id="bg"></div>
<div id="wrapper"  *ngIf="destination">
    <header id="header">
        <app-navbar> </app-navbar>
        <div class="content">
            <div [ngClass]="{'inner': showMyClass}">
                <h1>{{destination.name}}</h1>
            </div>
        </div>
    </header>

    <div id="mainMulti" @fadeIn *ngIf="!isEmpty; else noDestination" style="padding-top: 5%;">
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div *ngFor="let dest of destinations" class="col-md-4 mt-4">
                <a routerLink="/home/destination/{{dest.id}}" routerLinkActive="active">
                    <div class="card profile-card-5">
                        <div class="card-img-block">
                            <img class="card-img-top" src="{{dest.imagePath}}" alt="Card image cap">
                        </div>
                        <div class="card-body pt-0">
                            <h5 class="card-title">{{dest.name}}</h5>
                        </div>
                    </div></a>
            </div>
        </div>
    </div>

    <ng-template #noDestination >
        <div fxLayout="row wrap">
            <div fxFlex="20%">
                <h2>Follow us on Social Media to find out new tours</h2>

                <div class="content">
                    <div class = "inner d-flex align-items-center justify-content-center" >
                        <ul class="icons">
                            <li>
                               <a href="https://www.facebook.com/madeinworldagency" role="button">
                                <fa-icon [icon]="faFacebook"></fa-icon>
                               </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/madeinworldagency/" role="button">
                                    <fa-icon [icon]="faInstagram"></fa-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <app-footer></app-footer>
</div>

