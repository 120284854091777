import {Destinations} from "./app/model/destinations";


export const DESTINATIONS: Destinations[] = [
  {id: 4, name: "CAMPANIA", imagePath:"assets/images/transfer/miwitna-trf01/image07.jpg"},
  {id: 15, name: "SICILY", imagePath:"assets/images/transfer/miwitct-trf02/image03.jpg"},
  {id: 14, name: "SARDINIA", imagePath:"assets/images/sardegna/miwitor-tour01/image01.jpg"},
  {id: 7, name: "LAZIO", imagePath:"assets/images/lazio/miwitrm-tour01/image11.jpg"}
  ]
export const TRANSFER_DESTINATIONS: Destinations[] = [
  {id: 4, name: "CAMPANIA", imagePath:"assets/images/transfer/miwitna-trf01/image07.jpg"},
  {id: 15, name: "SICILY", imagePath:"assets/images/transfer/miwitct-trf02/image03.jpg"}
]

