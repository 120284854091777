import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TourService} from "../tour.service";
import {DestinationService} from "../destination.service";
import {Tour} from "../model/tour";
import {Destination} from "../model/destination";
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class DestinationComponent {

  showMyClass : boolean;
  destination : Destination | undefined;
  tours : Tour[] = [];
  isEmpty= true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tourService: TourService,
    private destinationService : DestinationService,
    private metaTagService: Meta, private titleService: Title
  ) {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);
    this.titleService.setTitle('Made in World Destination');
    this.metaTagService.addTags([
      { name: 'description', content: 'Tour, Transfer and Service in Italy filtered by Region' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular,Destination,Destinations, Tour, Transfer, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Destination' },
      { property: 'og:description', content: 'Tour, Transfer and Service in Italy filtered by Region' },
      { property: 'og:image', content: 'assets/images/transfer/miwitna-trf01/image07.jpg' },
      { property: 'og:image', content: 'assets/images/transfer/miwitct-trf02/image03.jpg' },
      { property: 'og:image', content: 'assets/images/sardegna/miwitor-tour01/image01.jpg' },
      { property: 'og:image', content: 'assets/images/lazio/miwitrm-tour01/image11.jpg' },
      { property: 'twitter:title', content: 'Made in World Destination' },
      { property: 'twitter:description', content: 'Tour, Transfer and Service in Italy filtered by Region' },
      { property: 'twitter:image', content: 'assets/images/transfer/miwitna-trf01/image07.jpg' },
      { property: 'twitter:image', content: 'assets/images/transfer/miwitct-trf02/image03.jpg' },
      { property: 'twitter:image', content: 'assets/images/sardegna/miwitor-tour01/image01.jpg' },
      { property: 'twitter:image', content: 'assets/images/lazio/miwitrm-tour01/image11.jpg' }
    ]);
  }
  ngOnInit(): void {
    this.getDestination();
    this.getTours();
  }

  getDestination(): void {
    console.log("destinations")
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.destinationService.getRegionDestination(id)
        .subscribe(destination => this.destination = destination);
  }
  getTours() : void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.tourService.getToursByDestination(id).subscribe(tours => {
      this.tours  = tours;
        if(this.tours.length > 0){
          this.isEmpty = false;
        }
    });
  }
}
