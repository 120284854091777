import { Component } from '@angular/core';
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {DestinationService} from "../destination.service";
import {Destinations} from "../model/destinations";
import {Meta, Title} from "@angular/platform-browser";
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
                style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class DestinationsComponent {

  showMyClass : boolean;
  destination : Destinations | undefined;
  destinations : Destinations[] = [];
  isEmpty= true;
  title : string;
  faFacebook = faFacebookF;
  faInstagram = faInstagram;


  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private destinationService : DestinationService,
      private metaTagService: Meta, private titleService: Title
  ) {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);
    this.titleService.setTitle('Made in World Destinations - Find all the Tour Destinations');
    this.metaTagService.updateTag({ name: 'description', content: 'Check all the Tour Destinations in Italy, From Lazio to Sicily,  check our destination list to pick your next adventure' });
    this.metaTagService.addTags([
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Destinations, Destination, Destinazione, Destinazioni, Tour, Transfer, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Destinations' },
      { property: 'og:description', content: 'Tour, Transfer and Service in Italy, Europe or World' },
      { property: 'twitter:title', content: 'Made in World Destinations' },
      { property: 'twitter:description', content: 'Tour, Transfer and Service in Italy, Europe or World' }
    ]);
  }
  ngOnInit(): void {
    this.getDestination();
    this.getDestinations();
  }
  getDestination(): void {
    console.log("destination")
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.destinationService.getDestination(id)
        .subscribe(destination => this.destination = destination);
  }
  getDestinations() : void {
    console.log("destinations")
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    this.destinationService.getDestinationRegions().subscribe(destinations => this.destinations  = destinations);

    if(this.destinations.length > 0 && id == 1){
      this.isEmpty = false;
    }
  }

}
