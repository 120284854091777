import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DestinationService} from "../destination.service";
import {Destinations} from "../model/destinations";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-transfer-destinations',
  templateUrl: './transfer-destinations.component.html',
  styleUrls: ['./transfer-destinations.component.css']
})
export class TransferDestinationsComponent {
  showMyClass : boolean;
  destinations : Destinations[] = [];
  isEmpty= true;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private destinationService : DestinationService,
              private metaTagService: Meta, private titleService: Title){
    this.titleService.setTitle('Made in World Transfer Destinations');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Transfer Destinations list of Made in World Agency' });
    this.metaTagService.addTags([
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Transfers, Destinations, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Transfer Destinations' },
      { property: 'og:description', content: 'Transfer Destinations list of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Transfer Destinations' }
    ]);}

  ngOnInit(): void {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);
    this.getDestinations();
  }

  getDestinations() : void {
    console.log("destinations")

    this.destinationService.getTransferDestinationRegions().subscribe(destinations => this.destinations  = destinations);

    if(this.destinations.length > 0){
      this.isEmpty = false;
    }
  }


}
