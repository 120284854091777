import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']

})
export class NavbarComponent {

  constructor(private router: Router,
              private metaTagService: Meta){
    this.metaTagService.addTags([
      { name: 'description', content: 'Navbar of Made in World Agency' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular,Navbar, Tour, Transfer, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Navbar' },
      { property: 'og:description', content: 'Navbar of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Navbar' }
    ]);
  }

  goToSection(): void {
    this.router.navigate(['/home'], { fragment: 'about' });
  }
}
