import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  //apiUrl = 'http://68.66.251.236:8444/reservation';
  apiUrlBase = 'https://madeinworldagency.com:8444';
  apiUrl = '/reservation';

  constructor(private http: HttpClient) { }

  sendRequestEmail(body: any){
    console.log("sending experience request email with body ="+body)
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };

    this.http.post(this.apiUrlBase + this.apiUrl + '/experience',body , {headers}).subscribe(
      (data:any)=>{
        console.log(data)},
      error => {
        console.log(error)
      }
    )
    ;
  }

  sendContactEmail(body: any){
    console.log("sending contact request email with body ="+body)
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };

    this.http.post(this.apiUrlBase + '/info/contact-us',body , {headers}).subscribe(
      (data:any)=>{
        console.log(data)},
      error => {
        console.log(error)
      }
    )
    ;
  }
}
