<div id="bg"></div>
<div id="wrapper">
  <header id="header">
    <app-navbar> </app-navbar>
    <div class="content">
      <div [ngClass]="{'inner': showMyClass}">
        <h1>TRANSFERS</h1>
      </div>

    </div>

  </header>
  <br>
  <div id="mainMulti" @fadeIn *ngIf="showMyClass" style="padding-top: 5%;">
    <div class="row row-cols-1 row-cols-md-3 g-4">

      <!--Profile Card 5-->

      <div *ngFor="let transfer of transfers" class="col-md-4 mt-4">
        <a routerLink="/home/transfer/{{transfer.id}}" routerLinkActive="active">
          <div class="card profile-card-5">
            <div class="card-img-block">
              <img class="card-img-top" src="{{transfer.imagePath+'/image01.jpg'}}" alt="Card image cap">
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title">{{transfer.name}}</h5>
              <p class="card-text">{{transfer.name}}</p>

            </div>
          </div></a>
      </div>

    </div>
  </div>
  <app-footer></app-footer>
</div>



