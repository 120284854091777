import { Injectable } from '@angular/core';
import {DESTINATION} from "../DESTINATION";
import { Observable, of } from 'rxjs';
import {Destination} from "./model/destination";
import {Destinations} from "./model/destinations";
import {DESTINATIONS, TRANSFER_DESTINATIONS} from "../DESTINATIONS";

@Injectable({
  providedIn: 'root'
})
export class DestinationService {

  constructor() { }

  getDestinations() :Observable<Destination[]>{
    const destinations = of(DESTINATION);
    return destinations;
  }

  getDestinationRegions() :Observable<Destinations[]>{
    const destinations = of(DESTINATIONS);
    return destinations;
  }
  getDestination(id: number): Observable<Destination> {
    const destination = DESTINATION.find(d => d.id === id)!;
    return of(destination);
  }

  getRegionDestination(id: number): Observable<Destinations> {
    const destination = DESTINATIONS.find(d => d.id === id)!;
    return of(destination);
  }

  getTransferDestinationRegions() :Observable<Destinations[]>{
    const destinations = of(TRANSFER_DESTINATIONS);
    return destinations;
  }
}
