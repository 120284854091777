<nav>
  <ul>
    <li><a routerLink="/home" routerLinkActive="active" href="#">HOME</a></li>
    <li><a routerLink="/home/destinations/1" routerLinkActive="active" href="#">TOURS</a></li>
    <li><a routerLink="/home/services" routerLinkActive="active" href="#">SERVICES</a></li>
    <li><a routerLink="/home/transfers" routerLinkActive="active" href="#" >TRANSFER</a></li>
    <li><a [routerLink]="['/home']" fragment="about" routerLinkActive="active" >ABOUT</a></li>
    <li><a routerLink="/home/contact" routerLinkActive="active" href="#">CONTACT</a></li>
  </ul>
</nav>

