import {Component, Input} from '@angular/core';
import {Tour} from '../model/tour';
import {ActivatedRoute, Router} from "@angular/router";
import {TourService} from "../tour.service";
import {ApiService} from "../api.service";
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {OndemandExperienceService} from "../ondemand-experience.service";
import {faFacebookMessenger} from "@fortawesome/free-brands-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Meta, Title} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class TourComponent {
  name:string;
  lastName:string;
  email:string;
  phone:string;
  message:string;
  date :any=new Date()
  formatDate : string;
  showMyClass : boolean;
  tour : Tour | undefined;
  faFacebookMessenger = faFacebookMessenger;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;
  images = [ '/image02.jpg', '/image03.jpg','/image04.jpg','/image05.jpg','/image06.jpg','/image07.jpg','/image08.jpg','/image09.jpg','/image10.jpg'];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tourService: TourService,
    private onDemandService: OndemandExperienceService,
    private apiService: ApiService,
    private metaTagService: Meta, private titleService: Title){
    this.titleService.setTitle('Made in World Tour');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Tour of Made in World Agency' });
    this.metaTagService.addTags([
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Tour, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Tour' },
      { property: 'og:description', content: 'Tour of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Tour' }
    ]);}

  ngOnInit(): void {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);
    this.getTour();
  }

  getTour(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.tourService.getTour(id)
      .subscribe(tour => {
        this.tour = tour;
        },
        (error) => {
          console.error('Error fetching tour details:', error);

        });
  }


  sendEmail(subject:string){
    console.log("sending email")
    this.formatDate = this.date.getDate()+'/'+(this.date.getMonth()+1)+'/'+this.date.getFullYear()
    const body = {
      "firstName": this.name,
      "lastName": this.lastName,
      "email": this.email,
      "tourName": subject,
      "phoneNumber": this.phone,
      "date": this.formatDate,
      "notes": this.message
    };

    this.apiService.sendRequestEmail(body);
    this.showPopup();
    this.clearInput();
  }
  isPopupOpen = false;

  showPopup() {
    this.isPopupOpen = true;
  }
  close(){
    this.isPopupOpen = false;
  }
  clearInput(){
    this.name = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.message = "";
  }

}
