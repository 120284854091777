import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ToursComponent } from './tours/tours.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { TourComponent } from './tour/tour.component';
import {ServiceComponent} from "./service/service.component";
import { DestinationComponent } from './destination/destination.component';
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {NgbDatepicker} from "@ng-bootstrap/ng-bootstrap";
import { TransferComponent } from './transfer/transfer.component';
import { TransfersComponent } from './transfers/transfers.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import { DestinationsComponent } from './destinations/destinations.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransferDestinationsComponent } from './transfer-destinations/transfer-destinations.component'
import {MatIconModule} from "@angular/material/icon";
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ToursComponent,
    ServicesComponent,
    ContactComponent,
    NavbarComponent,
    FooterComponent,
    ServiceComponent,
    TourComponent,
    DestinationComponent,
    TransferComponent,
    TransfersComponent,
    DestinationsComponent,
    TransferDestinationsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgbDatepicker,
    MatSidenavModule,
    FontAwesomeModule,
    MatIconModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent
  ],
  providers: [ MatDatepickerModule, provideAnimations()],
  bootstrap: [AppComponent]
})
export class AppModule { }
