import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Transfer} from "./model/transfer";

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  //apiUrl = 'http://68.66.251.236:8444/transfer';
  apiUrl = 'https://madeinworldagency.com:8444/transfer';

  constructor(private http: HttpClient) { }

  getTransfers() :Observable<Transfer[]>{

    console.log("find tours")
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };
    return this.http.get<Transfer[]>(this.apiUrl+'/find-all',{headers});
  }

  getTransfer(id: number): Observable<Transfer> {

    console.log("find transfer by id =" +id)
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };

    return this.http.get<Transfer>(this.apiUrl+'/find/'+id,{headers});
  }
  getTransfersByDestinationId(id: number) :Observable<Transfer[]>{

    console.log("find tours")
    const headers = { 'Content-Type': 'application/json','Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2IiwiaWF0IjoxNzAxNDY4ODI5LCJleHAiOjE3MDIzMzI4Mjl9.oOk_7xqVaAem0ord4XsUKMt87ivaX9La7MjVOmekYk8' };
    return this.http.get<Transfer[]>(this.apiUrl+'/find-by-destination/'+id,{headers});
  }
}
