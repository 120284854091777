import {Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {Destination} from "../model/destination";
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from "@angular/router";

import {DestinationService} from "../destination.service";
import {TourService} from "../tour.service";
import {Tour} from "../model/tour";
import {OndemandExperienceService} from "../ondemand-experience.service";
import {TransferService} from "../transfer.service";
import {Services} from "../model/services";
import {Transfer} from "../model/transfer";
import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import * as $ from 'jquery';
import {ImageItem} from "../model/imageItem";
declare global {
    interface Window {
        taValidate: any;
    }
}
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit{
  showMyClass : boolean;
  tour : Tour | undefined;
  service : Services | undefined;
  transfer : Transfer | undefined;
  faEarthEurope = faEarthEurope;
  images : ImageItem [] = [];
  secondImages : ImageItem [] = [];
  thirdImages : ImageItem [] = [];

private widgetLoaded = false;


  constructor(private router: Router,private route: ActivatedRoute,
              private tourService: TourService, private destinationService : DestinationService,
              private onDemandExperienceService: OndemandExperienceService,private transferService: TransferService,
              private metaTagService: Meta, private titleService: Title,
              private renderer: Renderer2) {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);
    this.titleService.setTitle('Made in World Homepage');
    this.metaTagService.addTags([
      { name: 'description', content: 'Tour, Transfer and Service in Italy' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Tour, Transfer, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Homepage' },
      { property: 'og:description', content: 'Tour, Transfer and Service in Italy' },
      { property: 'twitter:title', content: 'Made in World Homepage' },
      { property: 'twitter:description', content: 'Tour, Transfer and Service in Italy' }
    ]);



  }

  destinations : Destination[] = [];

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    this.loadScript()
    this.getDestinations();
    this.getTour();
    this.getService();
    this.getTransfer();
    this.getImages();
  }
  getImages(){



    this.images = [


      { src: 'assets/images/partners/Logo_Musement_Member-of_Desktop_RGB.webp', alt: 'musement logo', width: '100', height: '70' },
      { src: 'assets/images/partners/find.png', alt: 'find logo', width: '100', height: '70' },
      { src: 'assets/images/partners/connecto.png', alt: 'connecto logo', width: '100', height: '70' },
      { src: 'assets/images/partners/bokun.webp', alt: 'bokun logo', width: '10', height: '100' },
      { src: 'assets/images/partners/TripAdvisor_Logo.svg', alt: 'trip advisor logo', width: '100', height: '70' },
      { src: 'assets/images/partners/get_your_guide.png', alt: 'get your guide logo', width: '5', height: '100' },

    ];

  }

  getDestinations(): void {
    this.destinationService.getDestinations()
      .subscribe(destinations => this.destinations = destinations);
  }
 getTransfer(): void {
   this.transferService.getTransfer(1)
       .subscribe(transfer => {
             this.transfer = transfer;
           });
 }
 getService(): void {
   this.onDemandExperienceService.getService(3)
       .subscribe(service => this.service = service);
 }

 getTour(): void {
   this.tourService.getTour(8).subscribe(tour => {
             this.tour = tour;
           });
 }

    private loadScript() {
        $.getScript("https://www.tripadvisor.com/WidgetEmbed-cdsratingsonlynarrow", function(){
            if (typeof(window.taValidate) != 'undefined') {
                window.taValidate();
            }
        });
    }

}
