import {Component, OnInit} from '@angular/core';
import {Tour} from "../model/tour";
import {TourService} from "../tour.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {animate, stagger, style, trigger, query, transition, state} from "@angular/animations";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
  trigger('pageAnimations', [
    transition(':enter', [
      query('.tour', [
        style({opacity: 0, transform: 'translateY(-100px)'}),
        stagger(30, [
          animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
            style({ opacity: 1, transform: 'none' }))
        ])
      ])
    ])
  ]),
     ]
})
export class ToursComponent implements OnInit{

  showMyClass : boolean;
  tours : Tour[] = [];

  constructor(private tourService: TourService, private activatedRoute: ActivatedRoute,
              private metaTagService: Meta, private titleService: Title){
  this.titleService.setTitle('Made in World Tours');
  this.metaTagService.updateTag(
    { name: 'description', content: 'Tours of Made in World Agency' });
  this.metaTagService.addTags([
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Tours, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Tours' },
      { property: 'og:description', content: 'Tours of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Tours' }
      ]);
    this.showMyClass = false;
    this.activatedRoute.paramMap.subscribe(params => {

      setTimeout(() => {

        this.showMyClass = true;

      }, 200);
    });

    console.log("navigate")
  }


  getTours() : void {
    this.tourService.getTours().subscribe(tours =>{
      this.tours  = tours
    });
  }

  ngOnInit(): void {

    this.getTours();
  }

}
