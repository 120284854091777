import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomepageComponent} from "./homepage/homepage.component";
import {ToursComponent} from "./tours/tours.component";
import {ServiceComponent} from "./service/service.component";
import {ServicesComponent} from "./services/services.component";
import {ContactComponent} from "./contact/contact.component";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import {TourComponent} from "./tour/tour.component";
import {DestinationComponent} from "./destination/destination.component";
import {TransferComponent} from "./transfer/transfer.component";
import {TransfersComponent} from "./transfers/transfers.component";
import {FooterComponent} from "./footer/footer.component";
import {DestinationsComponent} from "./destinations/destinations.component";
import {TRANSFER_DESTINATIONS} from "../DESTINATIONS";
import {TransferDestinationsComponent} from "./transfer-destinations/transfer-destinations.component";

const routes: Routes = [
  { path: 'home', component: HomepageComponent },
  { path: '', component: HomepageComponent},
  { path: 'home/tours', component: ToursComponent},
  { path: 'home/services', component: ServicesComponent},
  { path: 'home/contact', component: ContactComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'home/tour/:id', component: TourComponent},
  { path: 'home/destination/:id', component: DestinationComponent},
  { path: 'home/service/:id', component: ServiceComponent},
  { path: 'home/transfer/:id', component: TransferComponent},
  { path: 'home/transfers',component: TransferDestinationsComponent},
  { path: 'home/transfers/:id', component: TransfersComponent},
  { path: 'home/destinations/:id', component: DestinationsComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes),   BsDatepickerModule.forRoot()],

  exports: [RouterModule]
})
export class AppRoutingModule { }
